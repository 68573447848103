import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Card,
  Dialog,
  DialogTitle,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import saveAs from "file-saver";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getGroups, getGroupsCSV, removeGroup } from "../../../api/group";
import useAuth from "../../../context/useAuth";
import Group from "../../../interfaces/group";
import { formatDate } from "../../../utils/formatDate";
import useDebounce from "../../../utils/useDebounce";
import MultiSelect from "../../form/MultiSelect";

interface IGroupList {
  teachers: { name: string; value: string }[];
  checkedLocation: any[];
  setCheckedLocation: any;
}

const List = ({
  teachers,
  checkedLocation,
  setCheckedLocation,
}: IGroupList) => {
  const { locations } = useAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [data, setData] = useState<any>([]);
  const [loading, setLoader] = useState<boolean>(true);
  const [checkedTeacher, setCheckedTeacher] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [records, setRecords] = useState(0);
  const [searchInput, setSearchInput] = useState<string>("");
  const [activeGroups, setActiveGroups] = useState("");
  const [monthYear, setMonthYear] = useState<
    | {
        startOfMonth: number | undefined;
        endOfMonth: number | undefined;
      }
    | undefined
  >(undefined);

  const debouncedSearch = useDebounce(searchInput, 300);

  const { user } = useAuth();

  const [openedMenu, setOpenedMenu] = useState<null | string>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setAnchorEl(e.currentTarget);
    setOpenedMenu(id);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setOpenedMenu(null);
  };

  const columns: GridColDef[] = [
    {
      field: "is_active",
      headerName: "Active",
      flex: 1,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.is_active ? "Aktiv" : "Joaktiv",
    },
    {
      field: "name",
      headerName: "Group Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "teacher",
      headerName: "Teacher",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.teacher.first_name + " " + params.row.teacher.last_name}`,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "language_level",
      headerName: "Language Level",
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.language_level?.level,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "start_date",
      headerName: "Date start",
      valueGetter: (params: GridValueGetterParams) =>
        `${formatDate(new Date(params.row.start_date), {
          day: "2-digit",
          month: "long",
          year: "numeric",
        })}`,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "end_date",
      headerName: "Date end",
      valueGetter: (params: GridValueGetterParams) =>
        `${formatDate(new Date(params.row.end_date), {
          day: "2-digit",
          month: "long",
          year: "numeric",
        })}`,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Actions",
      renderCell: (params: GridRenderCellParams) => (
        <div>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => openMenu(e, params.row.id)}
          >
            <MoreVertIcon />
          </IconButton>
          {openedMenu === params.row.id && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open
              onClose={closeMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  closeMenu();
                }}
              >
                <Link
                  to={`/groups/${params?.id}`}
                  target="_blank"
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                    width: 100,
                    color: "#A250A6",
                  }}
                >
                  View
                  <VisibilityIcon />
                </Link>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpen(true);
                  setId(params.row?.id);
                }}
                disabled={
                  user?.user_level === 4 || user?.user_level === 5
                    ? true
                    : false
                }
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width={100}
                  sx={{ color: "#DA5050" }}
                >
                  Delete
                  <DeleteIcon />
                </Box>
              </MenuItem>
            </Menu>
          )}
        </div>
      ),
      flex: 1,
      maxWidth: 70,
    },
  ];

  const downloadCSV = async () => {
    const res = await getGroupsCSV(
      monthYear,
      checkedLocation,
      checkedTeacher,
      debouncedSearch,
      activeGroups
    );
    if ("error" in res) {
      return toast.error(res.message);
    } else {
      let options: any = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return saveAs(
        res,
        `SPXEDU-Groups - (${new Date().toLocaleDateString(
          "en-EN",
          options
        )}).csv`
      );
    }
  };

  const getAllGroups = async () => {
    const { data: res, records } = await getGroups(
      page,
      monthYear,
      checkedLocation,
      checkedTeacher,
      debouncedSearch,
      activeGroups
    );
    if ("error" in res) {
      setLoader(false);
      return toast.error(res.message);
    } else {
      setData(
        res.map((item: Group) => {
          item.id = item._id;
          item.location = item.location.name;
          return item;
        })
      );
      setRecords(records);
      setLoader(false);
    }
  };

  const deleteGroup = async () => {
    const res = await removeGroup(id);

    if ("error" in res) {
      return toast.error(res.message);
    } else {
      const _data = data.filter((group: any) => group._id !== id);
      setData(_data);
      toast.success("Group deleted");
      setOpen(false);
    }
  };

  useEffect(() => {
    getAllGroups();
  }, [
    page,
    debouncedSearch,
    checkedLocation,
    activeGroups,
    checkedTeacher,
    monthYear,
  ]);

  return (
    <>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Are you sure you want to delete this group?</DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
          <Button
            variant="contained"
            color="error"
            onClick={() => deleteGroup()}
          >
            Delete
          </Button>
          <Button
            sx={{ ml: 1 }}
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </Box>
      </Dialog>
      <Card elevation={0} sx={{ py: 2, px: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" component="div" gutterBottom>
            Groups
          </Typography>
          <Button variant="outlined" onClick={() => downloadCSV()}>
            <Typography>Download CSV</Typography>
            <DownloadIcon sx={{ mx: 1 }} />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: { lg: "center" },
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "space-between",
            pb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", lg: "row" },
            }}
          >
            <FormControl size="small" sx={{ width: { xs: "100%", lg: 110 } }}>
              <TextField
                size="small"
                value={activeGroups}
                onChange={(e) => setActiveGroups(e.target.value)}
                select
                label="Activity"
                sx={{ mt: { xs: 1, lg: 0 } }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </TextField>
            </FormControl>
            <MultiSelect
              values={checkedLocation}
              setValues={setCheckedLocation}
              options={locations}
              label="Location"
            />
            <MultiSelect
              values={checkedTeacher}
              setValues={setCheckedTeacher}
              options={teachers}
              label="Teacher"
            />

            <DatePicker
              format="MM/YYYY"
              label="Month/Year"
              views={["year", "month"]}
              onMonthChange={(newDate) => {
                const startOfMonth = newDate?.startOf("month").valueOf();
                const endOfMonth = newDate?.endOf("month").valueOf();
                setMonthYear({ startOfMonth, endOfMonth });
              }}
              onChange={(e) => {
                if (!e) {
                  setMonthYear({
                    startOfMonth: undefined,
                    endOfMonth: undefined,
                  });
                }
                console.log("e", e);
              }}
              slotProps={{
                field: {
                  clearable: true,
                },
                textField: {
                  size: "small",
                },
              }}
            />

            <TextField
              size="small"
              sx={{ width: { xs: "100%", lg: "300px" }, ml: 1 }}
              placeholder="Search by name ..."
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Box>
        </Box>
        <div style={{ height: 650, width: "100%", background: "#fff" }}>
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            paginationMode="server"
            disableColumnFilter
            disableColumnSelector
            rowCount={records}
            loading={loading}
            onPageChange={(e) => {
              setPage(e);
            }}
            getCellClassName={(params: GridCellParams<string>) => {
              if (!params.field.startsWith("is_active")) {
                return "";
              }
              return params.value === "Aktiv" ? "green bold" : "red bold";
            }}
            sx={{
              "& .bold": {
                fontWeight: "bold",
              },
              "& .red": {
                color: "#CD5C5C",
              },
              "& .green": {
                color: "#097969",
              },
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default List;
