import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, Card, TextField, Typography } from "@mui/material";
import {
  DataGrid,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";
import saveAs from "file-saver";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getGroupStudentsCSV, getGroupUsersFilter } from "../../../api/group";
import useAuth from "../../../context/useAuth";
import GroupStudent from "../../../interfaces/groupUser";
import { getLastIndex } from "../../../utils/getLastIndex";
import useDebounce from "../../../utils/useDebounce";
import MultiSelect from "../../form/MultiSelect";
import GroupAttendance from "../groupAttendance";
import GroupUserModal from "../groupUserModal";
import RemoveStudentModal from "../removeStudentModal";
import StudentModal from "../studentModal";
import TransferModal from "../transferModal";
import { createTableColums } from "./columns";

interface GroupUsersProps {
  languageLevel: string;
  groupLocation: string;
  monthsArray: { name: string; value: number }[];
}

interface ICustomFooter {
  data: GroupStudent[];
  paginationProps: any;
}

const CustomFooter = ({ data, paginationProps }: ICustomFooter) => {
  const params = useParams();
  const currentGroupId = params.id;
  const calculateActiveInactive = (students: GroupStudent[]) => {
    let activeCount = 0;
    let inactiveCount = 0;

    students.forEach((student) => {
      const { status, deleted, transferDetails = [] } = student;
      const lastTransfer =
        transferDetails?.[transferDetails.length - 1] || null;

      let isTransferredToSameGroup = false;
      let isTransferredToDifferentGroup = false;

      if (lastTransfer) {
        isTransferredToSameGroup =
          lastTransfer?.toGroup?._id?.toString() === currentGroupId;
        isTransferredToDifferentGroup =
          lastTransfer?.toGroup?._id?.toString() !== currentGroupId;
      }

      if (!deleted) {
        if (status) {
          activeCount++;
        } else if (
          !status &&
          (transferDetails?.length === 0 || transferDetails === null)
        ) {
          activeCount++;
        } else if (!status && isTransferredToSameGroup) {
          activeCount++;
        }
      }

      if (deleted) {
        inactiveCount++;
      } else if (
        !deleted &&
        transferDetails?.length > 0 &&
        isTransferredToDifferentGroup
      ) {
        inactiveCount++;
      }
    });

    return { activeCount, inactiveCount };
  };

  const { activeCount, inactiveCount } = calculateActiveInactive(data);

  return (
    <GridFooterContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "0 16px",
        }}
      >
        <Typography>
          Active Students: {activeCount} / Inactive Students: {inactiveCount}
        </Typography>
      </Box>
      <GridPagination {...paginationProps} />
    </GridFooterContainer>
  );
};

const GroupUsers: React.FC<GroupUsersProps> = ({
  languageLevel,
  groupLocation,
  monthsArray,
}) => {
  const params = useParams();
  const [data, setData] = useState<GroupStudent[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [viewModalOpen, setViewModalOpen] = useState<boolean>(false);
  const [transferModalOpen, setTransferModalOpen] = useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
  const [currentStudent, setCurrentStudent] = useState<GroupStudent>(data[0]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [paidMonth, setPaidMonth] = useState<any[]>([]);
  const [loading, setLoader] = useState<boolean>(true);
  const { user } = useAuth();

  const debouncedSearch = useDebounce(searchInput);

  const getRowClassName = (params: any) => {
    const { transferredFromThisGroup } = params.row;
    const { deleted } = params.row;
    if (transferredFromThisGroup || deleted) {
      return "transferred-row";
    }
    return "";
  };

  const downloadCSV = async () => {
    const res = await getGroupStudentsCSV(
      paidMonth,
      debouncedSearch,
      params.id
    );
    if ("error" in res) {
      return toast.error(res.message);
    } else {
      let options: any = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return saveAs(
        res,
        `SPXEDU-StudentsPayment - (${new Date().toLocaleDateString(
          "en-EN",
          options
        )}).csv`
      );
    }
  };

  const getCurrentGroupUsersFilter = async (
    id: string,
    debouncedSearch: string,
    paidMonth: any[]
  ) => {
    const res = await getGroupUsersFilter(id, debouncedSearch, paidMonth);
    if ("error" in res) {
      setLoader(false);
      return toast.error(res.message);
    } else {
      setData(
        res.map((item) => {
          const transferDetails = item.transferDetails || [];
          const lastTransferDetail = getLastIndex(transferDetails);
          const transferredToThisGroup =
            lastTransferDetail && lastTransferDetail.toGroup._id === params.id;
          const transferredFromThisGroup =
            lastTransferDetail &&
            lastTransferDetail.fromGroup._id === params.id;

          return {
            ...item,
            id: item._id,
            transferredToThisGroup: transferredToThisGroup,
            transferredFromThisGroup: transferredFromThisGroup,
            lastTransferDetails: lastTransferDetail,
          };
        })
      );

      setLoader(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      getCurrentGroupUsersFilter(params.id, debouncedSearch, paidMonth);
    }
  }, [paidMonth, debouncedSearch, params.id]);

  const columns = createTableColums({
    getCurrentGroupUsersFilter,
    handleActionButtons: (row, type) => {
      setCurrentStudent(row as GroupStudent);
      switch (type) {
        case "edit":
          setViewModalOpen(true);
          break;
        case "delete":
          setRemoveModalOpen(true);
          break;
        case "transfer":
          setTransferModalOpen(true);
          break;
        default:
          setViewModalOpen(true);
      }
    },
    paramsId: params.id,
    searchInput: debouncedSearch,
    paidMonth,
  });

  return (
    <>
      {open && (
        <GroupUserModal
          open={open}
          setOpen={setOpen}
          groupLocation={groupLocation}
          groupId={params.id ? params.id : ""}
          setData={setData}
        />
      )}
      {transferModalOpen && (
        <TransferModal
          open={transferModalOpen}
          setOpen={setTransferModalOpen}
          groupId={params.id ? params.id : ""}
          student={currentStudent}
          setData={setData}
          languageLevel={languageLevel}
          getCurrentGroupUsersFilter={getCurrentGroupUsersFilter}
          paramsId={params.id}
          searchInput={debouncedSearch}
          paidMonth={paidMonth}
        />
      )}
      {currentStudent && (
        <StudentModal
          open={viewModalOpen}
          setOpen={setViewModalOpen}
          student={currentStudent}
          setStudent={setCurrentStudent}
          setData={setData}
        />
      )}
      {currentStudent && (
        <RemoveStudentModal
          removeModalOpen={removeModalOpen}
          setRemoveModalOpen={setRemoveModalOpen}
          student={currentStudent}
          setData={setData}
        />
      )}
      <GroupAttendance data={data} />
      <Card>
        <Box sx={{ width: "100%", background: "#fff" }}>
          <Typography variant="h5" sx={{ mt: 2, ml: 2 }}>
            Group Payments
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user && [1, 2, 3].includes(user.user_level) && (
              <Button
                sx={{ m: 2 }}
                onClick={() => setOpen(true)}
                variant="contained"
              >
                Add Student
              </Button>
            )}

            <Button
              variant="outlined"
              onClick={downloadCSV}
              sx={{ display: "flex", ml: 2, mr: 4 }}
              disabled={data.length === 0 ? true : false}
            >
              <Typography>Download CSV</Typography>
              <DownloadIcon sx={{ mx: 1 }} />
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: { lg: "center" },
              flexDirection: { xs: "column", lg: "row" },
              justifyContent: "space-between",
              pb: 2,
              px: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
              <MultiSelect
                values={paidMonth}
                setValues={setPaidMonth}
                options={monthsArray}
                label="Paid Months"
              />
              <TextField
                size="small"
                placeholder="Search by name ..."
                sx={{ ml: 1 }}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </Box>
          </Box>
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={data}
              //@ts-ignore
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              loading={loading}
              getRowClassName={getRowClassName}
              components={{
                Footer: CustomFooter,
              }}
              componentsProps={{
                footer: {
                  data,
                },
              }}
              sx={{
                ".transferred-row": {
                  background: "#f7f5f5",
                  opacity: 0.5,
                  pointerEvents: "none",
                },
              }}
            />
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default GroupUsers;
